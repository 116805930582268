import { render, staticRenderFns } from "./sys_module_roles.vue?vue&type=template&id=d5172492&scoped=true&"
import script from "./sys_module_roles.vue?vue&type=script&lang=js&"
export * from "./sys_module_roles.vue?vue&type=script&lang=js&"
import style0 from "./sys_module_roles.vue?vue&type=style&index=0&id=d5172492&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d5172492",
  null
  
)

export default component.exports